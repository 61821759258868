import {
  InformationCircleIcon,
  ChartBarIcon,
  SunIcon,
} from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
import { InitGame } from './components/modals/InitGame'
import Countdown from 'react-countdown'
import {
  WORDLE_TITLE,
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  CORRECT_WORD_MESSAGE,
  NOT_FOUND_MESSAGE,
  OVER_TIME_MESSAGE,
} from './constants/strings'
import { isWordInWordList, isWinningWord, solution } from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  // saveGameStateToLocalStorage,
} from './lib/localStorage'

import './App.css'

const ALERT_TIME_MS = 2000

function App() {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isInitGameModalOpen, setInitGameModalOpen] = useState(true)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
  const [isRunning, setRunning] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [isTimeOver, setIsTimeOver] = useState(false)
  const [currentTime, setCurrentTime] = useState(180000)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )
  const [successAlert, setSuccessAlert] = useState('')
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (loaded.guesses.length === 6 && !gameWasWon) {
      setIsGameLost(true)
    }
    return loaded.guesses
  })

  const [stats, setStats] = useState(() => loadStats())

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDarkMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  useEffect(() => {
    // saveGameStateToLocalStorage({ guesses, solution })
  }, [guesses])

  useEffect(() => {
    if (isGameWon) {
      setSuccessAlert(
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      )
      setTimeout(() => {
        setSuccessAlert('')
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
    if (isTimeOver) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
  }, [isGameWon, isGameLost, isTimeOver])

  const onChar = (value: string) => {
    if (!isInitGameModalOpen && !isTimeOver) {
      if (currentGuess.length < 5 && guesses.length < 6 && !isGameWon) {
        setCurrentGuess(`${currentGuess}${value}`)
      }
    }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = () => {
    if (!isInitGameModalOpen) {
      if (isStatsModalOpen) {
        window.location.reload()
      }
      if (isGameWon || isGameLost) {
        return
      }
      if (!(currentGuess.length === 5)) {
        setIsNotEnoughLetters(true)
        return setTimeout(() => {
          setIsNotEnoughLetters(false)
        }, ALERT_TIME_MS)
      }

      if (!isWordInWordList(currentGuess)) {
        setIsWordNotFoundAlertOpen(true)
        return setTimeout(() => {
          setIsWordNotFoundAlertOpen(false)
        }, ALERT_TIME_MS)
      }

      const winningWord = isWinningWord(currentGuess)

      if (currentGuess.length === 5 && guesses.length < 6 && !isGameWon) {
        setGuesses([...guesses, currentGuess])
        setCurrentGuess('')

        if (winningWord) {
          setStats(addStatsForCompletedGame(stats, guesses.length))
          return setIsGameWon(true)
        }

        if (guesses.length === 5) {
          setStats(addStatsForCompletedGame(stats, guesses.length + 1))
          setIsGameLost(true)
        }
      }
    } else {
      setInitGameModalOpen(false)
      setCurrentTime(Date.now() + currentTime)

      if (!isTimeOver) {
        setRunning(true)
      }
    }
  }

  return (
    
    <div className="py-8 max-w-7xl mx-auto sm:px-6 lg:px-8 flex-col flex h-screen">
      <div className="flex w-80 mx-auto items-center mb-8 mt-12">
        <img src="./logo.png" alt="" />
      </div>
      <div className="flex w-80 mx-auto items-center mb-8 mt-12 justify-center">
        {/* <h1 className="text-xl grow font-bold dark:text-white">
          {WORDLE_TITLE}
        </h1> */}
        {isRunning == true && (
          <Countdown
            className="text-5xl color-rosal font-medium text-gray-900 dark:text-gray-100 "
            date={currentTime}
            daysInHours={true}
            onComplete={() => {
              setStats(addStatsForCompletedGame(stats, 6))
              setIsTimeOver(true)
            }}
            onTick={() => {
              setCurrentTime(currentTime)
            }}
          />
        )}
        {/* <SunIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"
          onClick={() => handleDarkMode(!isDarkMode)}
        /> */}
        {/* <InformationCircleIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"
          onClick={() => setIsInfoModalOpen(true)}
        />
        <ChartBarIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"
          onClick={() => setIsStatsModalOpen(true)}
        /> */}
      </div>

      <Grid guesses={guesses} currentGuess={currentGuess} />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
      />
      <InitGame
        isOpen={isInitGameModalOpen}
        handleClose={() => setInitGameModalOpen(false)}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        isTimeOver={isTimeOver}
        handleShare={() => {
          setSuccessAlert(GAME_COPIED_MESSAGE)
          return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
        }}
      />
      <AboutModal
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />

      <Alert
        message={NOT_ENOUGH_LETTERS_MESSAGE}
        message2="Debe de haber 5 letras para continuar"
        isOpen={isNotEnoughLetters}
      />
      <Alert
        message2="Inténtalo con otra"
        message={WORD_NOT_FOUND_MESSAGE}
        isOpen={isWordNotFoundAlertOpen}
      />
      <Alert
        message={CORRECT_WORD_MESSAGE(solution)}
        message2={NOT_FOUND_MESSAGE}
        isOpen={isGameLost}
      />
      <Alert
        message={CORRECT_WORD_MESSAGE(solution)}
        message2={OVER_TIME_MESSAGE}
        isOpen={isTimeOver}
      />
      <Alert
        message={'¡Enhorabuena!'}
        message2={WIN_MESSAGES}
        isOpen={isGameWon}
        variant="success"
      />
    </div>
  )
}

export default App

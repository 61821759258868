import { GameStats } from '../../lib/localStorage'
import { BaseModal } from './BaseModal'

import { Fragment } from 'react'
type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InitGame = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal
      title={'Bienvenido a Wordle'}
      isOpen={isOpen}
      handleClose={handleClose}
      isShowClose={false}
    >
      <p>Pulsa "ENTER" para iniciar el juego</p>
    </BaseModal>
  )
}

export const WORDS = [
  'Rabia',
  'Polio',
  'Gripe',
  'Virus',
  'Sabin',
  'Naive',
  'Covid',
  'Zaire',
  'Ebola',
  'Huevo',
  'Vivas',
  'Salud',
  'Carne',
  'Nalga',
  'Brazo',
  'Aguja',
  'Dolor',
  'Suero',
  'Pauta',
  'NITAG',
  'Vasto',
  'Typhi',
  'Spike',
  'Latex',
  'AEMPS',
  'Brote',
  'Dosis',
  'Jeryl',
];
